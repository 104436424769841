jQuery($ => {
    "use strict";
/*
Transfer of header forms to body
*/
    $('body').append($('.header-search-form'));
    $('body').append($('.header-autorize-form'));
    $('body').append($('.buy-lk-form'));
    $('body').append($('.subscrible-journal-form'));
    $('body').append($('.term-condition'));
    $('body').append($('.header-registration-form'));
/*
Sticky header
*/
    $(window).scroll(function(){
        if (jQuery(window).scrollTop() > 185) {
            jQuery('header').addClass('active');
        } else {
            jQuery('header').removeClass('active');
        }
    });

/*
Active page link
*/
    $(function () {
        var location = window.location.href;

        $('.main-navigation li').each(function () {
            var link = $(this).find('a').attr('href');

            if (location == link) {
                $(this).addClass('active');
            }
        });
    });



/*
Announcements slider
*/
// $(document).ready(function(){
//     var $status = $('.announcements-paging-info');
//     var $slickElement = $('.announcements-slider');
//     $slickElement.on('init reInit afterChange', function(event, slickElement, currentSlide, nextSlide){
//    //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
//     var i = (currentSlide ? currentSlide : 0) + 1;
//     $status.html('<span class="current">' + addZero(i) + '</span>' + '<span class="delimiter">'  + '/' + '</span>' + '<span class="total">' + addZero(slickElement.slideCount) + '</span>');
//         });

//     $('.announcements-slider').slick({
//             slidesToShow: 3,
//         autoplay: false,
//         centerMode: false,
//         arrows: true,
//         prevArrow: $('.prev'),
//         nextArrow: $('.next'),
//         responsive: [
//             {
//               breakpoint: 560,
//               settings: {
//                 slidesToShow: 1
//               }
//             }
//         ]
//     });
// });



/*
Mobile menu
*/
    $('.hamburger-button').click(function(){
      $(this).toggleClass('open');
      $('.mobile-menu').toggleClass('open');
    });


/* Advertising block (r-block) */

    $(".homepage-publications > .r-btn").click(function() {
        $(".homepage-publications > .r-block").addClass("open");
    });

    $(".homepage-publications > .r-block > .title-column > .popup-window-slide-btn-close").click(function() {
        $(".homepage-publications > .r-block").removeClass("open");
    });

    /*
    Display options
    */
    $(".display-options > .list").click(function() {
        $(this).addClass("active");
        $('.homepage-publications > .content-block > .publications').addClass("display-list");
        $(".display-options > .tile").removeClass("active");
        $('.homepage-publications > .content-block > .publications').removeClass("display-tile");

    });

    $(".display-options > .tile").click(function() {
        $(this).addClass("active");
        $('.homepage-publications > .content-block > .publications').addClass("display-tile");
        $(".display-options > .list").removeClass("active");
        $('.homepage-publications > .content-block > .publications').removeClass("display-list");
    });

        /* Authorss slider */

    $('ul.category-switch li').click(function(){
        var tab_id = $(this).attr('data-tab');

        $('ul.category-switch li').removeClass('current');
        $('.homepage-publications > .heading-block .op-title-row > .title').removeClass('current');
        $('.homepage-publications > .content-block > .publications').removeClass('current');
        $(this).addClass('current');
        $("."+tab_id).addClass('current');
    })

    /*
    Search form
    */

    $("a.popup-window").click(function(e) {
        e.preventDefault();
        var open = '.'+$(this).data('open');
        $(open +".popup-window-slide").addClass("open");
    });

    $(".subscribe-btn").click(function() {
        $(".subscribe-form").addClass("open");
    });

    $(".search-btn-open").click(function() {
        $(".header-search-form").addClass("open");
    });

    $(".question-answer-btn-open").click(function() {
        $(".header-question-form").addClass("open");
    });
    $(".paid-service-answer-btn-open").click(function() {
        $(".paid-services-form").addClass("open");
    });

    //show modal widget-login-form
    //open login
    $('.login-cabinet-btn').click(function (e) {
        e.preventDefault();
        $('.header-autorize-form').addClass("open");
    });

    //open registration form
    $(".header-autorize-form .user-signup").click(function(e) {
        $(".header-autorize-form").removeClass("open");
        var email = $("#loginform-email").val();
        var pass = $("#loginform-password").val();
        if(email != ''){
            $("#signupform-email").val(email);
        }
        if(pass != ''){
            $("#signupform-password").val(pass);
        }
        $('.header-registration-form').addClass("open");
    });


    //show modal widget-login-form
    //open login
    $('.prepaid').click(function (e) {
        e.preventDefault();
        if ($('.buy-lk-form').length){
            $('.buy-lk-form').addClass("open");
        } else {
            $('.header-autorize-form').addClass("open");
        }
    });


    // close any open popup-window-slide
    $('body').on('click', '.popup-window-slide.open .popup-window-slide-btn-close', function() {
        $(this).parents('.popup-window-slide').removeClass("open");
    });

    $('body').on('click','a.subscribe-journal', function(e) {
        e.preventDefault();
        // if ($('.popup-window-slide').hasClass('.subscrible-journal-form')){
        if ($('.subscrible-journal-form').length){
            $('.subscrible-journal-form.popup-window-slide').addClass('open');
        } else {
            $('.header-autorize-form').addClass("open");
        }
    });
    //products page add/remowe class open to buy-product-form

    $(".products-item .btn-buy-product").click(function(e) {
        e.preventDefault();
        $('.buy-product-form .product-title').text("\"" + $(this).parent().parent().find(".wrap").find(".title").text() +"\"");
        $('.buy-product-form .product-date').text($(this).parent().parent().find(".wrap").find(".published-date").text() + ' ('+ $(this).parent().parent().find(".wrap").find('.product-number').text()+')');
        $("#buyproductform-product_id").val( $(this).parent().parent().attr('data-key'));
        $('.buy-product-form .product-price .price').text($(this).parent().find(".product-price").text());
        $('.buy-product-form .base-price').text($(this).parent().find(".product-price").text());
        $("#buyproductform-price").val($(this).parent().find(".product-price").text());

        $('.buy-product-form').addClass("open");
    });
    $(".buy-product-form> .op-title-row > .popup-window-slide-btn-close").click(function() {
        $(".buy-product-form").removeClass("open");
    });

    /*-----  end products page  ------*/

    $('#term-conditions').on('change', function () {
        if ($('#term-conditions').prop('checked')) {
            $('.btn-term-conditions').attr('disabled', false);
            $('.btn-term-conditions').removeClass('disabled');
        } else {
            $('.btn-term-conditions').attr('disabled', true);
            $('.btn-term-conditions').addClass('disabled');
        }
    });

    //checked/unchecked conditions checkbox
    $('#conditions').on('change', function () {
        if ($('#conditions').prop('checked')) {
            $('.header-registration-form .btn-user-sign-in').attr('disabled', false);
            $('.header-registration-form .btn-user-sign-in').removeClass('disabled');
        } else {
            $('.header-registration-form .btn-user-sign-in').attr('disabled', true);
            $('.header-registration-form .btn-user-sign-in').addClass('disabled');
        }
    });

    //ajax login user-login btn-user-sign-in
    $('.user-logine').on('click', function (e) {
        e.preventDefault();
        var identity= $('#loginform-identity').val();
        var password= $('#loginform-password').val();

        let data = {'identity': identity, 'password': password};
        $.ajax({
            type: "POST",
            url: "shop/product/favorite-product",
            data: {data: data},
            dataType: "json",
            success: function (res) {
                console.log(res);
            },
            error: function (e) {
                console.log('Error!' + e);
            }
        });
    });

    // click on the datePicker btn
    $('.article-index .btn-specific-date').on('click', function (e) {
        $('#articlesearch-published_at-kvdate>.btn.btn-secondary').click();
    });
    $('.questions-index .btn-specific-date').on('click', function (e) {
        $('#questionssearch-created_at-kvdate>.btn.btn-secondary').click();
    });
    $('.documents-index .btn-specific-date').on('click', function (e) {
        $('#documentssearch-date_publication-kvdate>.btn.btn-secondary').click();
    });
    $('.seminar-index .btn-specific-date').on('click', function (e) {
        $('#seminarsearch-date__event-kvdate>.btn.btn-secondary').click();
    });


    //select onChange question-rubric
    $('.question-rubric').on('change', function() {
        document.location.href = '/cabinet/questions/index/?QuestionsSearch%5Brubric%5D='+this.value;
    });
    //open modal modal-request-login-reset
    $('body').on('click','a.request-login-reset',function(e){
        e.preventDefault();
        $('.header-autorize-form.popup-window-slide').removeClass('open');
        $('#modal-request-login-reset').modal("show");
    })


    //add trigger classChanged
        var originalAddClassMethod = jQuery.fn.addClass;
        var originalRemoveClassMethod = jQuery.fn.removeClass;
        jQuery.fn.addClass = function(){
            var result = originalAddClassMethod.apply( this, arguments );
            jQuery(this).trigger('classChanged');
            return result;
        };
        jQuery.fn.removeClass = function(){
            var result = originalRemoveClassMethod.apply( this, arguments );
            jQuery(this).trigger('classChanged');
            return result;
        };
        // add \ remove modal-backdrop when changing class open
    jQuery('.popup-window-slide').bind('classChanged', function(){
        if($(this).hasClass("open")){
            if($('.modal-backdrop').length==0){
                $(this).after("<div class=\"modal-backdrop fade show\"></div>");
            }
        }else {
            $("div.modal-backdrop.fade.show").remove()
        }
    });
// remove class open when clicking modal-backdrop
    $(document).on('click', '.modal-backdrop.fade.show', function(){
       $(".popup-window-slide.open").removeClass("open");
    });

    //change separator in the table datepicker
    $(".kv-field-seperator").text(">>");

});

//Show password by icon
$('body').on('click', '.header-autorize-form .password-control', function(){
    if ($('#loginform-password').attr('type') == 'password'){
        $(this).addClass('view');
        $('#loginform-password').attr('type', 'text');
    } else {
        $(this).removeClass('view');
        $('#loginform-password').attr('type', 'password');
    }
    return false;
});
$('body').on('click', '.user-service-page .password-control', function(){
    if ($('#loginform-password').attr('type') == 'password'){
        $(this).addClass('view');
        $('#loginform-password').attr('type', 'text');
    } else {
        $(this).removeClass('view');
        $('#loginform-password').attr('type', 'password');
    }
    return false;
});
$('body').on('click', '.header-registration-form .password-control', function(){

    if ($('#signupform-password_confirm').attr('type') == 'password'){
        $(this).addClass('view');
        $('#signupform-password_confirm').attr('type', 'text');
        $('#signupform-password').attr('type', 'text');
    } else {
        $(this).removeClass('view');
        $('#signupform-password').removeClass('view');
        $('#signupform-password_confirm').attr('type', 'password');
        $('#signupform-password').attr('type', 'password');
    }
    return false;
});
$('body').on('click', '.user-service-page .password-control', function(){

    if ($('#signupform-password_confirm').attr('type') == 'password'){
        $(this).addClass('view');
        $('#signupform-password_confirm').attr('type', 'text');
        $('#signupform-password').attr('type', 'text');
    } else {
        $(this).removeClass('view');
        $('#signupform-password').removeClass('view');
        $('#signupform-password_confirm').attr('type', 'password');
        $('#signupform-password').attr('type', 'password');
    }
    return false;
});
$('.cabinet-menu-button').click(function(){
  $('.cabinet .sidebar').toggleClass('open');
});
